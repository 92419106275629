@use "sass/ui-library/utilities/variables/spacing";
@use "sass/ui-library/utilities/variables/text/fonts";
@use "sass/ui-library/utilities/variables/color/neutral";
@use "sass/ui-library/utilities/variables/color/accent";
@use "sass/ui-library/utilities/variables/color/status";
@use "../../../sass/ui-library/utilities/mixins";

$width: 280px;

.location-popup {
  $base: &;
  z-index: 2;

  /* need to set the popup's maximum height as its minimum height so that the popup*/
  /* is always visible when placed on the map*/
  min-height: 275px;

  .card-heading {
    -webkit-user-select: text;
       -moz-user-select: text;
        -ms-user-select: text;
            user-select: text;
  }

  .card-heading__title {
    cursor: pointer;
  }

  .ev-connector {
    padding: 0;
  }

  /* need to set the popup direction to column to disguse the min-height workaround*/
  &.maplibregl-popup-anchor-right,
  &.maplibregl-popup-anchor-left {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }

  &.maplibregl-popup-anchor-top.context-popup,
  &.maplibregl-popup-anchor-top-left.context-popup,
  &.maplibregl-popup-anchor-top-right.context-popup {
    margin-top: -17px;
  }

  &.maplibregl-popup-anchor-bottom.context-popup,
  &.maplibregl-popup-anchor-bottom-left.context-popup,
  &.maplibregl-popup-anchor-bottom-right {
    margin-top: 0;
  }

  &.maplibregl-popup-anchor-left.context-popup,
  &.maplibregl-popup-anchor-bottom-left.context-popup,
  &.maplibregl-popup-anchor-top-left.context-popup {
    margin-left: -8px;
  }

  &.maplibregl-popup-anchor-right.context-popup,
  &.maplibregl-popup-anchor-bottom-right.context-popup,
  &.maplibregl-popup-anchor-top-right.context-popup {
    margin-left: 8px;
  }

  &.maplibregl-popup-anchor-bottom.search-pin {
    margin-top: -31px;
  }

  &.maplibregl-popup-anchor-bottom.waypoint-pin {
    margin-top: -43px;
  }

  &.maplibregl-popup-anchor-bottom.waypoint-circle {
    margin-top: -8px;
  }

  &.maplibregl-popup-anchor-bottom.search-highlighted-pin {
    margin-top: -42px;
  }

  &.maplibregl-popup-anchor-top.search-pin,
  &.maplibregl-popup-anchor-top-right.search-pin,
  &.maplibregl-popup-anchor-top-left.search-pin,
  &.maplibregl-popup-anchor-top.search-highlighted-pin,
  &.maplibregl-popup-anchor-top-right.search-highlighted-pin,
  &.maplibregl-popup-anchor-top-left.search-highlighted-pin {
    margin-top: -5px;
  }

  &.maplibregl-popup-anchor-top.waypoint-pin,
  &.maplibregl-popup-anchor-top-right.waypoint-pin,
  &.maplibregl-popup-anchor-top-left.waypoint-pin {
    margin-top: -15px;
  }

  &.maplibregl-popup-anchor-top.waypoint-circle,
  &.maplibregl-popup-anchor-top-right.waypoint-circle,
  &.maplibregl-popup-anchor-top-left.waypoint-circle {
    margin-top: 6px;
  }

  &.maplibregl-popup-anchor-bottom-left.search-pin,
  &.maplibregl-popup-anchor-left.search-pin,
  &.maplibregl-popup-anchor-left.search-highlighted-pin,
  &.maplibregl-popup-anchor-bottom-left.waypoint-pin,
  &.maplibregl-popup-anchor-left.waypoint-pin {
    margin-left: 13px;
  }

  &.maplibregl-popup-anchor-bottom-left.search-highlighted-pin {
    margin-left: 16px;
  }

  &.maplibregl-popup-anchor-bottom-left.waypoint-circle,
  &.maplibregl-popup-anchor-left.waypoint-circle,
  &.maplibregl-popup-anchor-top-left.waypoint-circle {
    margin-left: 9px;
  }

  &.maplibregl-popup-anchor-bottom-right.search-pin,
  &.maplibregl-popup-anchor-right.search-pin,
  &.maplibregl-popup-anchor-right.search-highlighted-pin,
  &.maplibregl-popup-anchor-bottom-right.waypoint-pin,
  &.maplibregl-popup-anchor-right.waypoint-pin {
    margin-left: -13px;
  }

  &.maplibregl-popup-anchor-bottom-right.search-highlighted-pin {
    margin-left: -16px;
  }

  &.maplibregl-popup-anchor-bottom-right.waypoint-circle,
  &.maplibregl-popup-anchor-top-right.waypoint-circle,
  &.maplibregl-popup-anchor-right.waypoint-circle {
    margin-left: -7px;
  }

  &__carousel {
    .poi-image__img-container {
      width: $width;
      height: 135px;
      background-color: neutral.$neutral_01;

      img {
        width: 100%;
        height: 100%;
        -o-object-fit: cover;
           object-fit: cover;
      }
    }
  }

  &.maplibregl-popup,
  .maplibregl-popup-content {
    width: $width;
    /* to be replaced with popup options or setMaxWidth method when it's added to the SDK*/
    max-width: $width !important;
  }

  .maplibregl-popup-content {
    padding: 0;
    -webkit-box-shadow: 0 4px 12px rgb(0 0 0 / 15%);
            box-shadow: 0 4px 12px rgb(0 0 0 / 15%);
    border-radius: 8px;

    .carousel-inner {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }
  }

  .maplibregl-popup-tip {
    display: none;
  }

  .maplibregl-popup-close-button {
    z-index: 2;
  }

  &__info {
    padding: spacing.$spacing-s spacing.$spacing-s 0 spacing.$spacing-s;
  }

  &__title-row {
    margin-bottom: spacing.$spacing-xs;
  }

  &__rating-price {
    margin-bottom: spacing.$spacing-xs;
  }

  &__create-route {
    margin-top: spacing.$spacing-xs;
  }

  &__charging-info {
    background: neutral.$neutral_01;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding: spacing.$spacing-xs;
    border-radius: 8px;
  }
}

.traffic-popup {
  $base: &;

  margin: spacing.$spacing-s;

  &--magnitude-1 {
    #{$base}__magnitude {
      color: status.$alert_02;
    }
  }

  &--magnitude-2 {
    #{$base}__magnitude {
      color: status.$waning_03;
    }
  }

  &--magnitude-3 {
    #{$base}__magnitude {
      color: status.$destructive_04;
    }
  }

  &__main-incident {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    div:first-child {
      margin-left: 0;
    }
  }

  &__icon-title-delay-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin-left: spacing.$spacing-m;
  }

  &__icon-title {
    color: neutral.$neutral_06;
  }

  &__delay {
    color: neutral.$neutral_04 !important;
  }

  &__magnitude {
    position: absolute;
    right: spacing.$spacing-s;
  }

  &__expected-and-description {
    background: neutral.$neutral_01;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding: spacing.$spacing-xs;
    margin-top: spacing.$spacing-s;
    border-radius: 8px;
  }

  &__end-date,
  &__description {
    color: neutral.$neutral_05;
  }

  &__related-incidents {
    margin-top: spacing.$spacing-s;
  }

  &__related-incidents-icons {
    svg {
      @include mixins.size(32px);
      padding: spacing.$spacing-xxxs;

      path {
        -webkit-transform: scale(0.8);
                transform: scale(0.8);
      }
    }

    svg:not(:first-child) {
      margin-left: spacing.$spacing-s;
    }
  }

  &__related-incidents-icons-extra {
    margin-left: 10px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    @include mixins.size(32px);
    border-radius: 50%;
    background: neutral.$neutral_01;
  }

  &__related-incidents-icons-extra-text {
    color: neutral.$neutral_06;
  }
}

.charging-popup {
  $base: &;

  margin: spacing.$spacing-s;

  &__icon-title-delay-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    svg {
      @include mixins.size(48px);
      padding: spacing.$spacing-xxxs;

      path {
        -webkit-transform: scale(1.5);
                transform: scale(1.5);
      }
    }
  }

  &__title-delay-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }

  &__title {
    color: neutral.$neutral_06;
  }

  &__delay {
    color: neutral.$neutral_04;
  }

  &__description {
    background: neutral.$neutral_01;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding: spacing.$spacing-xs;
    border-radius: 8px;
  }

  &__list {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  &__list li{
    text-transform: capitalize;
  }

  &__separator {
    margin: 5px;
  }
}

.category-shortcut-popup {
  $base: &;
  background: transparent;
  width: 90px;
  height: 90px;

  .maplibregl-popup-tip {
    display: none;
  }

  .maplibregl-popup-content {
    background: transparent;
    padding: 0;
    -webkit-box-shadow: none;
            box-shadow: none;
  }

  &__restaurant,
  &__gas-station,
  &__hotel,
  &__charging-station {
    background: white;
    border-radius: 50%;
    position: absolute;
    cursor: pointer;
    -webkit-box-shadow: 0 1px 2px rgb(0 0 0 / 10%);
            box-shadow: 0 1px 2px rgb(0 0 0 / 10%);
  }

  &__restaurant {
    top: 0;
    left: 33px;
  }

  &__gas-station {
    top: 33px;
    right: 0;
  }

  &__hotel {
    left: 33px;
    bottom: 0;
  }

  &__charging-station {
    top: 33px;
    left: 0;
  }
}

.route-popup {
  $base: &;
  cursor: pointer;

  &--active {
    cursor: default;

    #{$base}__details {
      @include mixins.horizontal-list(accent.$accent_04);
      background-color: accent.$accent_05;
      border-radius: spacing.$spacing-xxs;
    }

    #{$base}__distance {
      color: accent.$accent_03;
    }

    #{$base}__duration {
      color: neutral.$white_100;
    }

    #{$base}__delay,
    #{$base}__no-delay {
      @include mixins.text-with-svg-color(neutral.$white_100);
    }

    &.maplibregl-popup-anchor-bottom {
      .maplibregl-popup-tip {
        border-bottom: none;
        border-top-color: accent.$accent_05;
      }
    }

    &.maplibregl-popup-anchor-top {
      .maplibregl-popup-tip {
        border-top: none;
        border-bottom-color: accent.$accent_05;
      }
    }

    &.maplibregl-popup-anchor-right {
      .maplibregl-popup-tip {
        border-right: none;
        border-left-color: accent.$accent_05;
      }
    }

    &.maplibregl-popup-anchor-left {
      .maplibregl-popup-tip {
        border-left: none;
        border-right-color: accent.$accent_05;
      }
    }
  }

  .maplibregl-popup-content {
    padding: 0;
    -webkit-box-shadow: 0 spacing.$spacing-xxs spacing.$spacing-s rgb(0 0 0 / 15%);
            box-shadow: 0 spacing.$spacing-xxs spacing.$spacing-s rgb(0 0 0 / 15%);
    border-radius: spacing.$spacing-xxs;
  }

  .maplibregl-popup-tip {
    border-width: spacing.$spacing-xxs;
  }

  &__details {
    @include mixins.horizontal-list(neutral.$neutral_06);
    padding: spacing.$spacing-xxs spacing.$spacing-xs;
    background-color: neutral.$white_100;
    border-radius: spacing.$spacing-xxs;
  }

  &__distance,
  &__duration {
    color: neutral.$neutral_06;
  }

  &__detail-entry {
    padding: spacing.$spacing-xxxs 0;

    &.accent-text-s {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
    }
  }

  &__delay {
    @include mixins.text-with-svg-color(status.$destructive_04);
  }

  &__no-delay {
    @include mixins.text-with-svg-color(status.$positive_03);
  }
}

.popup-while-map-moving {
  .maplibregl-popup-content {
    pointer-events: none;
  }
}
