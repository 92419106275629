@use "sass/ui-library/utilities/variables/spacing";
@use "sass/ui-library/utilities/mixins";
@use "sass/ui-library/utilities/variables/text/fonts";
@use "sass/ui-library/utilities/variables/color/neutral";
@use "sass/ui-library/utilities/variables/color/accent";

.sortable-inputs {
  $this: &;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  position: relative;

  /* dotted line between waypoint markers*/
  &:before {
    content: "";
    position: absolute;
    top: 44px;
    bottom: 40px;
    left: 19px;
    border-left: 2px dotted accent.$accent_03;
  }

  &__sortable-element {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    z-index: 3; /* avoid disappearing when drag and dropping*/
    padding-bottom: 6px;
    &:last-of-type {
      padding-bottom: 0;
    }

    &--hovered:not(&--highlighted) {
      #{$this}__drag-handle-container {
        & > #{$this}__drag-icon {
          opacity: 1;
          display: block;
        }
      }

      #{$this}__delete-btn {
        opacity: 1;
      }

      .position-bullet {
        display: none;
      }
    }

    &--focused:not(&--hovered) {
      #{$this}__drag-handle-container {
        & > #{$this}__search-icon {
          opacity: 1;
          display: block;
          path {
            fill: accent.$accent_03;
          }
        }
      }

      .position-bullet {
        display: none;
      }
    }

    .input-control [id^=waypoint] {
      font-family: fonts.$gilroysemibold;
      color: neutral.$white_100;
      width: 264px;
      background: accent.$accent_08;
      border-width: 0 0 1px 0;
      border-radius: 2px 2px 0 0;
      border-color: accent.$accent_03;
      padding: 0;

      &::-webkit-input-placeholder {
        font-family: fonts.$proxima;
        color: accent.$accent_03;
      }

      &::placeholder,
      &::-webkit-input-placeholder {
        font-family: fonts.$proxima;
        color: accent.$accent_03;
      }

      &:focus {
        color: neutral.$white_100;
        background: accent.$accent_07;
      }
    }
  }

  &__drag-handle-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    cursor: -webkit-grab;
    cursor: grab;
    margin-right: spacing.$spacing-s;
    height: spacing.$spacing-xxl;
    background: accent.$accent_08;
    padding: {
      top: spacing.$spacing-xs;
      bottom: spacing.$spacing-xs;
      left: spacing.$spacing-s;
    }

    .position-bullet svg path {
      fill: accent.$accent_02;
    }

    .position-bullet--numbered {
      color: accent.$accent_02;
    }

    &--dragging {
      /* hide component if being dragged*/
      opacity: 0;
    }

    & > svg {
      display: none;
      opacity: 0;
      @include mixins.transition(opacity);
    }
  }

  &__drag-icon path {
    fill: accent.$accent_04;
  }

  &__delete-btn {
    margin-left: spacing.$spacing-s;
    opacity: 0;
    /*@include mixins.transition(opacity);*/
    /* hate to use !important but because of is-container setting padding to 0*/
    padding-right: spacing.$spacing-s !important;
  }

  &__highlight-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    padding-bottom: spacing.$spacing-xs;
    padding-top: spacing.$spacing-xxxs;
  }
}
