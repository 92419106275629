// --- PRIMARY COLORS ---
$brand-red: #df1b12;
$white: #ffffff;
$black: #000000;
$brand-blue: #3f9cd9;

// --- SECONDARY COLORS ---
$teal: #008d8d;
$coral: #f29ac2;
$indigo: #004b7f;
$cadmium: #fdc530;

// --- SHADES ---
$dark-red: #b1110e;
$dark-blue: #2683c0;
$light-blue: #bcdcf5;
$dark-teal: #006967;
$dark-coral: #ec619f;
$light-teal: #65a7a9;
$opaque-black: rgba(0, 0, 0, 0.6);
