@use "sass/ui-library/utilities/variables/text/fonts";
@use "sass/ui-library/utilities/variables/spacing";
@use "sass/ui-library/utilities/variables/color/neutral";
@use "sass/ui-library/utilities/variables/color/accent";
@use "sass/ui-library/utilities/variables/color/status";
@use "../../../sass/ui-library/utilities/variables/effects";
@use "../../../sass/ui-library/utilities/mixins";
@use "../../../sass/ui-library/utilities/variables/transitions";

.main-menu {
  $base: &;
  $full-width-menu: 350px;
  $side-spacing: spacing.$spacing-l;
  $zindex-modal: 1050; /*copied from bootstrap (todo:try to reuse variables instead)*/
  $side-bar-in-time: transitions.$transition-duration-xl;
  $overlay-in-time: transitions.$transition-duration-l;
  $side-bar-out-time: transitions.$transition-duration-m;
  $overlay-out-time: $side-bar-out-time;

  z-index: $zindex-modal;

  &__overlay,
  &__side-bar {
    z-index: $zindex-modal;
    position: fixed;
    top: 0;
    left: 0;
  }

  &__overlay {
    @include mixins.size(100%, 100%);
    background-color: rgba(0, 0, 0, 0.3);
    opacity: 0;
    /*transition done when side bar is being hidden*/
    @include mixins.transition(opacity, $overlay-out-time, ease-out);
    pointer-events: none;
  }

  &__side-bar {
    @include mixins.size($full-width-menu, 100%);
    -webkit-transform: translateX(-#{$full-width-menu});
            transform: translateX(-#{$full-width-menu});
    background-color: neutral.$white_100;
    overflow-x: hidden;
    -webkit-box-shadow: effects.$drop-shadow-right-m-light;
            box-shadow: effects.$drop-shadow-right-m-light;
    /*transition done when side bar is being hidden*/
    @include mixins.transition("transform", $side-bar-out-time, ease-in);
  }

  &--open {
    #{$base}__overlay {
      opacity: 1;
      pointer-events: auto;
      /*transition done when side bar is being shown*/
      @include mixins.transition(opacity, $overlay-in-time, ease-out);
    }

    #{$base}__side-bar {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      /*transition done when side bar is being shown*/
      @include mixins.transition("transform", $side-bar-in-time, ease-out);
    }
  }

  &__content-wrapper {
    width: $full-width-menu;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    height: 100%;
    position: relative;
  }

  &__heading {
    font-family: fonts.$gilroysemibold;
    border-bottom: 1px solid neutral.$neutral_03;
    padding-bottom: spacing.$spacing-xxs;
    color: neutral.$neutral_04;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
  }

  &__expander {
    .lists-item {
      padding-left: 0;
      padding-right: 0 !important;
    }
  }

  &__block {
    margin: spacing.$spacing-m $side-spacing;

    &:first-of-type {
      padding-top: spacing.$spacing-xxxl;
    }
  }

  .toggle-group {
    .control {
      margin: spacing.$spacing-s 0;
    }

    .toggle__body {
      font-family: fonts.$proxima;
      font-size: 12px;
      line-height: 16px;
    }
  }

  &__header {
    margin: spacing.$spacing-l $side-spacing;
  }

  &__close {
    position: absolute;
    left: 304px;
    top: 25px;
  }

  &__link-to-panel {
    margin: spacing.$spacing-xs spacing.$spacing-m 0;

    &:hover {
      .main-menu-item {
        background-color: neutral.$neutral_01;
      }
    }
  }

  &__body {
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }

  &__legal {
    margin: spacing.$spacing-m $side-spacing spacing.$spacing-xxxl $side-spacing;
    color: neutral.$neutral_04;
  }

  &__legal-links {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }

  &__legal-links-text {
    color: neutral.$neutral_04;
    font-size: 12px;
  }

  &__legal-links-text:hover {
    color: neutral.$neutral_05;
    text-decoration: underline;
  }

  &__versions {
    display: none;
    margin: spacing.$spacing-m $side-spacing;
  }

  &__footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }

  &__dev-info {
    width: 100%;
  }

  .text-with-icon {
    font-family: fonts.$gilroysemibold;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.01em;

    &--secondary {
      font-family: fonts.$gilroybold;
      font-size: 14px;
      line-height: 18px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      letter-spacing: 0.01em;
      margin: 0;
    }
  }
}

.main-menu-item {
  $color: neutral.$neutral_06;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-radius: 12px;
  padding: spacing.$spacing-s spacing.$spacing-xs;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: neutral.$white_100;
  @include mixins.text-with-svg-color($color);
  @include mixins.transition("background-color");

  &--secondary {
    @include mixins.text-with-svg-color(neutral.$neutral_04);
  }

  &__text {
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    padding: 0 spacing.$spacing-m;
    text-align: left;
  }

  &__icon {
    -ms-flex-negative: 0;
        flex-shrink: 0;
  }
}
