.favourite-edit-modal {
  $base: &;
  display: block;

  &__favourite-type-selector {
    margin-bottom: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }

  &__favourite-home-selector {
    &.control {
      margin-right: 58px;
    }
  }
}
