@use "sass/ui-library/utilities/variables/spacing";
@use "sass/ui-library/utilities/variables/effects";
@use "sass/ui-library/utilities/variables/color/neutral";
@use "sass/ui-library/utilities/mixins";
@use "sass/utilities/variables/dimentions";

.route-timeline {
  position: absolute;
  background: rgba(neutral.$neutral_01, 0.9);
  height: 200px;
  left: (
    dimentions.$base-width + dimentions.$base-left-space + spacing.$spacing-m
  );
  right: 58px;
  bottom: 55px;
  border-radius: 8px;
  -webkit-box-shadow: effects.$drop-shadow-down-m-light;
          box-shadow: effects.$drop-shadow-down-m-light;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: spacing.$spacing-m;

  &__heading {
    padding-bottom: spacing.$spacing-xs;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    :first-child {
      margin-right: spacing.$spacing-xxs;
    }
  }

  &__timeline {
    height: 100%;
  }

  &__graphic {
    -webkit-box-flex: 1;
        -ms-flex: 1 auto;
            flex: 1 auto;
    position: relative;
  }

  &__range {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 95px;
    right: -2px;
    @include mixins.size(unset);

    &--initial {
      .rc-slider-handle {
        opacity: 0.4;

        &:hover {
          opacity: 1;
        }
      }
    }

    .rc-slider-rail {
      display: none;
    }

    .rc-slider-track {
      height: 100%;
      top: 0;
      z-index: 0;
      opacity: 0.3;
    }

    .rc-slider-handle {
      height: 100%;
      left: 0;
      border-radius: 2px 0 0 2px;
      width: 6px;
      @include mixins.transition(all);

      &-2 {
        left: unset;
        right: 0;
        border-radius: 0 2px 2px 0;
      }
    }
  }

  &__viewport-range {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 95px;
    right: -2px;
    @include mixins.size(unset);

    .rc-slider-rail {
      display: none;
    }

    .rc-slider-track {
      height: 100%;
      top: 0;
      z-index: 0;
      opacity: 0.3;
      background-color: neutral.$neutral_03
    }

    .rc-slider-viewport-track {
      height: 100%;
      top: 0;
      z-index: 0 !important;
      opacity: 0.1 !important;
    }

    .rc-slider-handle {
      visibility: hidden;
    }
  }
}
