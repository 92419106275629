@use "sass/ui-library/utilities/variables/color/brand-colors";
@use "sass/ui-library/utilities/variables/spacing";
@use "sass/ui-library/utilities/variables/text/fonts";
@use "sass/utilities/variables/dimentions";

.sales-modal {
  /*workaround for not having consistent modals*/
  z-index: 1056;
  pointer-events: none;

  &__content {
    max-width: 378px;
    background: brand-colors.$white;
    border-radius: spacing.$spacing-s;
    padding: spacing.$spacing-m;
    pointer-events: auto;
  }

  &__header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }

  &__close-wrapper {
    -ms-flex-item-align: end;
        align-self: end;
    cursor: pointer;

    .btn-close {
      opacity: 1;
    }
  }

  &__img {
    padding-top: spacing.$spacing-l;
    max-width: 188px;
  }

  &__body {
    padding-top: spacing.$spacing-s;
  }

  &__paragraph {
    margin: 0;
    padding-top: spacing.$spacing-s;
    font-size: 12px;
    line-height: 16px;

    &-intro {
      font-family: fonts.$gilroybold;
    }
  }

  &__paragraph--tick {
    padding-top: spacing.$spacing-l;
  }

  &__tick {
    color: brand-colors.$brand-blue;
  }

  &__footer {
    padding-top: spacing.$spacing-xl;
  }

  &__button-wrapper {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    gap: 8px;
  }

  &__button {
    .text {
      font-size: 12px;
    }
  }

  &__button--secondary {
    background: brand-colors.$white;
    border: 1px solid brand-colors.$brand-blue;

    &:hover, &:first-child:active {
      border: 1px solid brand-colors.$brand-blue;
    }

    .text {
      color: brand-colors.$brand-blue;
    }

    @media (max-width: dimentions.$max-width-mobile) {
      &:hover {
        background: brand-colors.$white;
      }
    }
  }

  &__checkbox {
    padding-top: spacing.$spacing-m;

    .toggle__text {
      font-size: 12px;
    }
  }

  .modal-dialog-centered {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}
